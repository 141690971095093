var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = {
  failure: function (errors, remaining) {
    if (errors.length < 1) {
      throw new Error("Failure must have errors");
    }

    return new Result({
      status: "failure",
      remaining: remaining,
      errors: errors
    });
  },
  error: function (errors, remaining) {
    if (errors.length < 1) {
      throw new Error("Failure must have errors");
    }

    return new Result({
      status: "error",
      remaining: remaining,
      errors: errors
    });
  },
  success: function (value, remaining, source) {
    return new Result({
      status: "success",
      value: value,
      source: source,
      remaining: remaining,
      errors: []
    });
  },
  cut: function (remaining) {
    return new Result({
      status: "cut",
      remaining: remaining,
      errors: []
    });
  }
};

var Result = function (options) {
  (this || _global)._value = options.value;
  (this || _global)._status = options.status;
  (this || _global)._hasValue = options.value !== undefined;
  (this || _global)._remaining = options.remaining;
  (this || _global)._source = options.source;
  (this || _global)._errors = options.errors;
};

Result.prototype.map = function (func) {
  if ((this || _global)._hasValue) {
    return new Result({
      value: func((this || _global)._value, (this || _global)._source),
      status: (this || _global)._status,
      remaining: (this || _global)._remaining,
      source: (this || _global)._source,
      errors: (this || _global)._errors
    });
  } else {
    return this || _global;
  }
};

Result.prototype.changeRemaining = function (remaining) {
  return new Result({
    value: (this || _global)._value,
    status: (this || _global)._status,
    remaining: remaining,
    source: (this || _global)._source,
    errors: (this || _global)._errors
  });
};

Result.prototype.isSuccess = function () {
  return (this || _global)._status === "success" || (this || _global)._status === "cut";
};

Result.prototype.isFailure = function () {
  return (this || _global)._status === "failure";
};

Result.prototype.isError = function () {
  return (this || _global)._status === "error";
};

Result.prototype.isCut = function () {
  return (this || _global)._status === "cut";
};

Result.prototype.value = function () {
  return (this || _global)._value;
};

Result.prototype.remaining = function () {
  return (this || _global)._remaining;
};

Result.prototype.source = function () {
  return (this || _global)._source;
};

Result.prototype.errors = function () {
  return (this || _global)._errors;
};

export default exports;
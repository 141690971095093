import _parser from "./lib/parser";
import _rules from "./lib/rules";
import _errors from "./lib/errors";
import _parsingResults from "./lib/parsing-results";
import _StringSource from "./lib/StringSource";
import _Token from "./lib/Token";
import _bottomUp from "./lib/bottom-up";
import _regexTokeniser from "./lib/regex-tokeniser";
var exports = {};
exports.Parser = _parser.Parser;
exports.rules = _rules;
exports.errors = _errors;
exports.results = _parsingResults;
exports.StringSource = _StringSource;
exports.Token = _Token;
exports.bottomUp = _bottomUp;
exports.RegexTokeniser = _regexTokeniser.RegexTokeniser;

exports.rule = function (ruleBuilder) {
  var rule;
  return function (input) {
    if (!rule) {
      rule = ruleBuilder();
    }

    return rule(input);
  };
};

export default exports;
export const Parser = exports.Parser,
      rules = exports.rules,
      errors = exports.errors,
      results = exports.results,
      StringSource = exports.StringSource,
      Token = exports.Token,
      bottomUp = exports.bottomUp,
      RegexTokeniser = exports.RegexTokeniser,
      rule = exports.rule;
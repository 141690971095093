var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

var fromArray = exports.fromArray = function (array) {
  var index = 0;

  var hasNext = function () {
    return index < array.length;
  };

  return new LazyIterator({
    hasNext: hasNext,
    next: function () {
      if (!hasNext()) {
        throw new Error("No more elements");
      } else {
        return array[index++];
      }
    }
  });
};

var LazyIterator = function (iterator) {
  (this || _global)._iterator = iterator;
};

LazyIterator.prototype.map = function (func) {
  var iterator = (this || _global)._iterator;
  return new LazyIterator({
    hasNext: function () {
      return iterator.hasNext();
    },
    next: function () {
      return func(iterator.next());
    }
  });
};

LazyIterator.prototype.filter = function (condition) {
  var iterator = (this || _global)._iterator;
  var moved = false;
  var hasNext = false;
  var next;

  var moveIfNecessary = function () {
    if (moved) {
      return;
    }

    moved = true;
    hasNext = false;

    while (iterator.hasNext() && !hasNext) {
      next = iterator.next();
      hasNext = condition(next);
    }
  };

  return new LazyIterator({
    hasNext: function () {
      moveIfNecessary();
      return hasNext;
    },
    next: function () {
      moveIfNecessary();
      var toReturn = next;
      moved = false;
      return toReturn;
    }
  });
};

LazyIterator.prototype.first = function () {
  var iterator = (this || _global)._iterator;

  if ((this || _global)._iterator.hasNext()) {
    return iterator.next();
  } else {
    return null;
  }
};

LazyIterator.prototype.toArray = function () {
  var result = [];

  while ((this || _global)._iterator.hasNext()) {
    result.push((this || _global)._iterator.next());
  }

  return result;
};

export default exports;
import _TokenIterator from "./TokenIterator";
var exports = {};
var TokenIterator = _TokenIterator;

exports.Parser = function (options) {
  var parseTokens = function (parser, tokens) {
    return parser(new TokenIterator(tokens));
  };

  return {
    parseTokens: parseTokens
  };
};

export default exports;
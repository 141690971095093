var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports = function (name, value, source) {
  (this || _global).name = name;
  (this || _global).value = value;

  if (source) {
    (this || _global).source = source;
  }
};

export default exports;
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

var TokenIterator = exports = function (tokens, startIndex) {
  (this || _global)._tokens = tokens;
  (this || _global)._startIndex = startIndex || 0;
};

TokenIterator.prototype.head = function () {
  return (this || _global)._tokens[(this || _global)._startIndex];
};

TokenIterator.prototype.tail = function (startIndex) {
  return new TokenIterator((this || _global)._tokens, (this || _global)._startIndex + 1);
};

TokenIterator.prototype.toArray = function () {
  return (this || _global)._tokens.slice((this || _global)._startIndex);
};

TokenIterator.prototype.end = function () {
  return (this || _global)._tokens[(this || _global)._tokens.length - 1];
}; // TODO: doesn't need to be a method, can be a separate function,
// which simplifies implementation of the TokenIterator interface


TokenIterator.prototype.to = function (end) {
  var start = this.head().source;
  var endToken = end.head() || end.end();
  return start.to(endToken.source);
};

export default exports;
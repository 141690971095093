var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports.error = function (options) {
  return new Error(options);
};

var Error = function (options) {
  (this || _global).expected = options.expected;
  (this || _global).actual = options.actual;
  (this || _global)._location = options.location;
};

Error.prototype.describe = function () {
  var locationDescription = (this || _global)._location ? (this || _global)._location.describe() + ":\n" : "";
  return locationDescription + "Expected " + (this || _global).expected + "\nbut got " + (this || _global).actual;
};

Error.prototype.lineNumber = function () {
  return (this || _global)._location.lineNumber();
};

Error.prototype.characterNumber = function () {
  return (this || _global)._location.characterNumber();
};

export default exports;
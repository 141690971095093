import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var util = _util;

var StringSource = exports = function (string, description) {
  var self = {
    asString: function () {
      return string;
    },
    range: function (startIndex, endIndex) {
      return new StringSourceRange(string, description, startIndex, endIndex);
    }
  };
  return self;
};

var StringSourceRange = function (string, description, startIndex, endIndex) {
  (this || _global)._string = string;
  (this || _global)._description = description;
  (this || _global)._startIndex = startIndex;
  (this || _global)._endIndex = endIndex;
};

StringSourceRange.prototype.to = function (otherRange) {
  // TODO: Assert that tokens are the same across both iterators
  return new StringSourceRange((this || _global)._string, (this || _global)._description, (this || _global)._startIndex, otherRange._endIndex);
};

StringSourceRange.prototype.describe = function () {
  var position = this._position();

  var description = (this || _global)._description ? (this || _global)._description + "\n" : "";
  return util.format("%sLine number: %s\nCharacter number: %s", description, position.lineNumber, position.characterNumber);
};

StringSourceRange.prototype.lineNumber = function () {
  return this._position().lineNumber;
};

StringSourceRange.prototype.characterNumber = function () {
  return this._position().characterNumber;
};

StringSourceRange.prototype._position = function () {
  var self = this || _global;
  var index = 0;

  var nextNewLine = function () {
    return self._string.indexOf("\n", index);
  };

  var lineNumber = 1;

  while (nextNewLine() !== -1 && nextNewLine() < (this || _global)._startIndex) {
    index = nextNewLine() + 1;
    lineNumber += 1;
  }

  var characterNumber = (this || _global)._startIndex - index + 1;
  return {
    lineNumber: lineNumber,
    characterNumber: characterNumber
  };
};

export default exports;